import React from 'react';
import {Link} from 'react-router-dom';

function Header() {
    return (
        <div className='shadow p-2 fw-bold border fs-2 '>
        <Link to="/" className="text-dark text-decoration-none">Doozy Colors</Link>
        </div>
    );
}

export default Header