import React from 'react';

function CodeCard(props) {
    return (
        <div className='border rounded-2'>
            <div className='px-2 py-1' style={{backgroundColor: props.headerBGColor, color: props.headerTextColor}}>{props.header}</div>
            <div className='p-2 bg-light' style={{whiteSpace: "pre-line"}}>
                <code>
                    {props.code}
                </code>
            </div>                           
        </div>
    );
}

export default CodeCard