export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
}

export function hexToRGB(hex) {
    var validHEXInput = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!validHEXInput) {
        return false;
    }
    var output = {
        r: parseInt(validHEXInput[1], 16),
        g: parseInt(validHEXInput[2], 16),
        b: parseInt(validHEXInput[3], 16),
    };
    return output;
  }

  export function rgbToCMYK(rgb) {
    var c, m, y, k;
    var r = rgb.r / 255;
    var g = rgb.g / 255;
    var b = rgb.b / 255;
    var max = Math.max(r, g, b);
    k = 1 - max;
    if (k === 1) {
        c = 0;
        m = 0;
        y = 0;
    } else {
        c = (1 - r - k) / (1 - k);
        m = (1 - g - k) / (1 - k);
        y = (1 - b - k) / (1 - k);
    }
    return { c: parseInt((c * 100).toFixed(0)), m: parseInt((m * 100).toFixed(0)), y: parseInt((y * 100).toFixed(0)), k: parseInt((k * 100).toFixed(0)) };
}

export function rgbToHSL(rgb1) {
    var min, max, i, l, s, maxcolor, h, rgb = [];
    rgb[0] = rgb1.r / 255;
    rgb[1] = rgb1.g / 255;
    rgb[2] = rgb1.b / 255;
    min = rgb[0];
    max = rgb[0];
    maxcolor = 0;
    for (i = 0; i < rgb.length - 1; i++) {
        if (rgb[i + 1] <= min) { min = rgb[i + 1]; }
        if (rgb[i + 1] >= max) { max = rgb[i + 1]; maxcolor = i + 1; }
    }
    if (maxcolor === 0) {
        h = (rgb[1] - rgb[2]) / (max - min);
    }
    if (maxcolor === 1) {
        h = 2 + (rgb[2] - rgb[0]) / (max - min);
    }
    if (maxcolor === 2) {
        h = 4 + (rgb[0] - rgb[1]) / (max - min);
    }
    if (isNaN(h)) { h = 0; }
    h = h * 60;
    if (h < 0) { h = h + 360; }
    l = ((min + max) / 2);
    if (min === max) {
        s = 0;
    } else {
        if (l < 0.5) {
            s = (max - min) / (max + min);
        } else {
            s = (max - min) / (2 - max - min);
        }
    }

    return { h: parseInt((h).toFixed(0)), s: parseInt((s * 100).toFixed(0)), l: parseInt((l * 100).toFixed(0)) };
}

export function rgbToHSV(rgb) {
    var computedH = 0;
    var computedS = 0;
    var computedV = 0;

    var r = rgb.r / 255;
    var g = rgb.g / 255;
    var b = rgb.b / 255;

    var minRGB = Math.min(r, Math.min(g, b));
    var maxRGB = Math.max(r, Math.max(g, b));

    // Black-gray-white
    if (minRGB === maxRGB) {
        computedV = minRGB;
        return { h: 0, s: 0, v: computedV.toFixed(0) };
    }

    // Colors other than black-gray-white:
    var d = (r === minRGB) ? g - b : ((b === minRGB) ? r - g : b - r);
    var h = (r === minRGB) ? 3 : ((b === minRGB) ? 1 : 5);
    computedH = (60 * (h - d / (maxRGB - minRGB)));
    computedS = ((maxRGB - minRGB) / maxRGB) * 100;
    computedV = maxRGB * 100;

    return { h: parseInt(computedH.toFixed(0)), s: parseInt(computedS.toFixed(0)), v: parseInt(computedV.toFixed(0)) };
}

export function getComplementaryColor (hex){
    const ind = parseInt(hex, 16);
    let iter = ((1 << 4 * hex.length) - 1 - ind).toString(16);
    while (iter.length < hex.length) {
       iter = '0' + iter;
    };
    return iter;
 }

export function hslToHex(h, s, l) {
    l /= 100.0;
    const a = s * Math.min(l, 1 - l) / 100.0;
    const f = n => {
      const k = (n + h / 30.0) % 12.0;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.floor(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };

    
    return `${f(0)}${f(8)}${f(4)}`;
  }

function toHex(n) {
    var hex = parseInt(n).toString(16);
    while (hex.length < 2) { hex = "0" + hex; }
    return hex;
}

export function rgbToHex(rgb) {
    return (toHex(rgb.r) + toHex(rgb.g) + toHex(rgb.b));
}

export function getHarmonizeColor(hex, start, end, interval) {
    const colors = [hex]
    const hsl = rgbToHSL(hexToRGB(hex))
    
    for(let i = start; i < end; i += interval) {
        const h1 = parseInt((hsl.h/12 + i) % 360)
        
        const c1 = hslToHex(h1,hsl.s,hsl.l)
        colors.push(c1)
    }

    return colors
}