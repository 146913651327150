import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import RandomHexColors from './components/RandomHexColors';
import Container from 'react-bootstrap/Container';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import HexColor from './pages/HexColor';

function App() {
  return (
    <BrowserRouter>
      <Header/>
      <Container className='px-2 my-4'>
        <Routes>
          <Route path="/" element={
            <div>
              <RandomHexColors/>
            </div>
          }>
          </Route>
          <Route path="/hex-color/:hexCode" element={<HexColor />}></Route>
          <Route path="*" element={<Navigate to="/"></Navigate>}></Route>
        </Routes>
      </Container>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
