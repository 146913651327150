import React from 'react';
import {Link} from 'react-router-dom';

function ColorCard(props) {
    return (
        <div className={(props.gap===false? "":"shadow border p-2") + " mb-2 position-relative rounded-2 text-center"}>
            <div className='p-5' style={{backgroundColor: "#" + props.color}}></div>
            <div className='p-1 border' style={{backgroundColor: "whitesmoke"}}>
                <Link to={"/hex-color/" + props.color} className="link-dark text-decoration-none fw-bold stretched-link">{"#" + props.color}</Link>
            </div>
        </div>
    );
}

export default ColorCard