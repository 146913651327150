import React from 'react';
import {useParams} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {getComplementaryColor, getHarmonizeColor, hexToRGB, hslToHex, rgbToCMYK, rgbToHex, rgbToHSL, rgbToHSV} from '../components/utils';
import ColorCard from '../components/ColorCard';
import CodeCard from '../components/CodeCard';
import { ProgressBar } from 'react-bootstrap';


function HexColor() {

    let {hexCode} = useParams();

    let rgb = hexToRGB(hexCode);
    let cmyk = rgbToCMYK(rgb);
    let hsl = rgbToHSL(rgb);
    let hsv = rgbToHSV(rgb);
    let hexForeColor = (hsl.l>50? "000000": "FFFFFF")


    let hexSplit=[hexCode.substring(0,2), hexCode.substring(2,4), hexCode.substring(4,6)]
    let triadicColors= [hexCode, hexSplit[2] + hexSplit[0] + hexSplit[1], hexSplit[1] + hexSplit[2] + hexSplit[0]]
    let analogousColors = [hexCode, hslToHex(parseInt(hsl.h-(30)),hsl.s, hsl.l), hslToHex(parseInt(hsl.h+(30)),hsl.s, hsl.l)]//getHarmonizeColor(hexCode,30,90,30)

    let colorTypes = [
        {name: "RGB", value: `${rgb.r}, ${rgb.g}, ${rgb.b}`},
        {name: "CMYK", value: `${cmyk.c}%, ${cmyk.m}%, ${cmyk.y}%, ${cmyk.k}%`},
        {name: "HSL", value: `${hsl.h}%, ${hsl.s}%, ${hsl.l}%`},
        {name: "HSV", value: `${hsv.h}°, ${hsv.s}°, ${hsv.v}°`},
        ];
    return (
        <Container>
            <Row className="gap-3">
                <Col lg={true}>
                    <h2>Color Hex: <b>#{hexCode}</b></h2>
                    <Container className='fs-1 d-flex align-items-center justify-content-center py-4 shadow border border-dark' 
                    style={{backgroundColor: "#" + hexCode, color: "#" + hexForeColor}}>
                        #{hexCode.toUpperCase()}
                        <br/>
                    </Container>
                </Col>
                
            </Row>
            <Row>
                <Col><br/><h2>Color Schemes for #{hexCode}</h2></Col>
            </Row>
            <Row className="gap-3 py-2">
                <Col lg={true}>
                    <h4>Triadic Colors</h4>
                    <Container>
                        <Row sm="auto" className='gap-2'>
                            {
                                Array.from(triadicColors, (triadicColor,i) =>{
                                    return(
                                        <Col key={i} className='p-0'>
                                            <ColorCard color={triadicColor}></ColorCard>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Container>
                </Col>
                <Col lg={true}>
                    <h4>Analogous Colors</h4>
                    <Container>
                        <Row sm="auto" className='gap-2'>
                            {
                                Array.from(analogousColors, (analogousColor,i) =>{
                                    return(
                                        <Col key={i} className='p-0'>
                                            <ColorCard color={analogousColor}></ColorCard>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Container>
                </Col>
                <Col lg={true}>
                    <h4>Complementary Colors</h4>
                    <Container>
                        <Row sm="auto" className='gap-2'>
                            <Col className='p-0'>
                                <ColorCard color={hexCode}></ColorCard>
                            </Col>
                            <Col className='p-0'>
                                <ColorCard color={rgbToHex({r: 255 - rgb.r, g: 255 - rgb.g, b: 255 - rgb.b})}></ColorCard>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className="gap-3">
                <Col lg="auto" >
                    <br/><h2>Color Spaces of <b>#{hexCode}</b></h2>
                    <Container className='border bg-light shadow'>
                        {
                            Array.from(colorTypes, (colorType,i) =>{
                                return(
                                <Row key={i} className='border-bottom gap-2 p-1 flex-nowrap'>
                                    <Col className='fw-bold px-2'>{colorType.name}</Col>
                                    <Col className='border-start'>{colorType.value}</Col>
                                </Row>
                                );
                            })
                        }
                    </Container>
                </Col>
                <Col lg="auto" >
                    <br/><h2>RGB Color Percentage</h2>
                    <Container className='border bg-light shadow gap-2 p-3 fw-bold'>
                        <div className="border mb-3 rounded-2 shadow">
                            <div className="p-1 rounded-2" style={{width: (rgb.r*100/255).toFixed(2) + "%", backgroundColor: "#FF0000",}}>
                                {(rgb.r*100/255).toFixed(2) + "%"}
                            </div>
                        </div>
                        <div className="border mb-3 rounded-2 shadow">
                            <div className="p-1 rounded-2" style={{width: (rgb.g*100/255).toFixed(2) + "%", backgroundColor: "#00FF00"}}>
                                {(rgb.g*100/255).toFixed(2) + "%"}
                            </div>
                        </div>
                        <div className="border rounded-2 shadow">
                            <div className="p-1 rounded-2 text-dark" style={{width: (rgb.b*100/255).toFixed(2) + "%", 
                            backgroundColor: "#0000FF"}}>
                                {(rgb.b*100/255).toFixed(2) + "%"}
                            </div>
                        </div>
                    </Container>
                </Col>
            </Row>
            <Row className="gap-3">
                <Col>
                    <h2><br/>Shades of Color #{hexCode}</h2>
                    <Container>
                        <Row sm="auto" className='gap-2'>
                            <Col className='p-0 d-flex flex-wrap'>
                                {
                                    Array.from(Array(12), (e,i) => {
                                        const l1 = Math.min(parseInt(hsl.l - hsl.l/12.0*i),100)
                                        return <ColorCard key={i} color={hslToHex(hsl.h,hsl.s, l1)} gap={false}></ColorCard>
                                    
                                    })
                                }
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col>
                    <h2><br/>Tints of Color #{hexCode}</h2>
                    <Container>
                        <Row className='gap-2 justify-content-center'>
                            <Col className='p-0 d-flex flex-wrap'>
                                {
                                    Array.from(Array(12), (e,i) => {
                                        const l1 = Math.min(parseInt(hsl.l + (100-hsl.l)/12.0*i),100)
                                        return <ColorCard key={i} color={hslToHex(hsl.h,hsl.s, l1)} gap={false}></ColorCard>
                                    })
                                }
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className="gap-3">
                
            </Row>
            <Row className="gap-3">
                <Col lg="auto">
                    <h2><br/>How to apply colors in Web Page / HTML / CSS Code?</h2><br/>
                    <Row className='gap-2'>
                        <Col>
                            <CodeCard header={"HEX Color in CSS Classes"} 
                                    headerBGColor={"#" + hexCode}
                                    headerTextColor={"#" + hexForeColor}
                                    code={ `.doozy_bgcolor { background-color: #${hexCode}; }
                                .doozy_forecolor { color: #${hexForeColor}; }
                                .doozy_bordercolor { border: 3px solid #${hexCode}; }
                                
                                `}>
                            </CodeCard>                            
                        </Col>
                        <Col>
                            <CodeCard header={"HEX Color in HTML Element Style"} 
                                    headerBGColor={"#" + hexCode}
                                    headerTextColor={"#" + hexForeColor}
                                    code={ `<div style="background-color: #${hexCode}; color: #${hexForeColor}; border: 3px solid #${hexCode};">
                                            Example to use hexadecimal color in style tag.
                                            </div>`}>
                            </CodeCard>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col><hr/></Col>
                    </Row>
                    <Row className='gap-2'>
                        <Col>
                            <CodeCard header={"RGB Color in CSS Classes"} 
                                    headerBGColor={"#" + hexCode}
                                    headerTextColor={"#" + hexForeColor}
                                    code={ `.doozy_bgcolor { background-color: rgb(${rgb.r}, ${rgb.g}, ${rgb.b});}
                                .doozy_forecolor { color: rgb(255,255,255); }
                                .doozy_bordercolor { border: 3px solid rgb(0, 0, 0); }
                                
                                `}>
                            </CodeCard>                            
                        </Col>
                        <Col>
                            <CodeCard header={"RGB Color in HTML Element Style"} 
                                    headerBGColor={"#" + hexCode}
                                    headerTextColor={"#" + hexForeColor}
                                    code={ `<div style="background-color: rgb(${rgb.r}, ${rgb.g}, ${rgb.b}); color: rgb(255,255,255); border: 3px solid rgb(0, 0, 0);">
                                                Example to use RGB color in style tag.
                                            </div>`}>
                            </CodeCard>                            
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        
    );
}

export default HexColor