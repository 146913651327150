import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';

function RandomHexColors() {
    return (
        <Container>
        <Row className='gap-4'>
        {
          Array.from(Array(30), (e,i) => {
            var randomHex = Math.floor(Math.random()*16777215).toString(16).padStart(6,"0");
            return (
                <Col key={i} gap={3} className='shadow border-light rounded-2 text-center border px-5 py-3' style={{backgroundColor: "#" + randomHex}}>
                    <Link to={"/hex-color/" + randomHex} className="bg-light link-dark  bg-opacity-25 m-2 p-2 fw-bold rounded-2">{"#" + randomHex}</Link>
                </Col>)
          } )
        }
        </Row>
        </Container>
    );
}

export default RandomHexColors